import AWS from 'aws-sdk';
import SignIn from './signIn'
import ChatUi from './chatUi'
import {useState} from 'react'
import configuration from './config'
const WEBSOCKET_BASE_URL = configuration['dev.mnemosyne.digital-platform.io'].websocket_base_url
const USERPOOL_ID = configuration['dev.mnemosyne.digital-platform.io'].userpool_id
const IDENTITY_POOL_ID =  configuration['dev.mnemosyne.digital-platform.io'].identity_pool_id
const AWS_REGION = configuration['dev.mnemosyne.digital-platform.io'].aws_region
const CLIENT_ID = configuration['dev.mnemosyne.digital-platform.io'].client_id

AWS.config.region = AWS_REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: IDENTITY_POOL_ID});


export const Mnemosyne = () => {
    //const [loggedIn,setLoggedIn] = useState(AWS.config.credentials.params.Logins && (Object.keys(AWS.config.credentials.params.Logins).length !== 0))
    const [cognitoUser,setCognitoUser] = useState(undefined)
    const [cognitoUserSession,setCognitoUserSession] = useState(undefined)
    const loggedIn = cognitoUser !== undefined && cognitoUserSession !== undefined
    if(cognitoUser !== undefined && !loggedIn) {
        cognitoUser.getSession(function(err, data) {
                if (err) {
                    console.log(err)
                } else {
                    const cognitoUserSession = data;
                    setCognitoUserSession(cognitoUserSession)
                    /*const yourIdToken = cognitoUserSession.getIdToken().jwtToken;
                    const yourAccessToken = cognitoUserSession.getAccessToken().jwtToken;*/
                }
            }
        )
    }
    return (
        <div>
            {!loggedIn && 
                <SignIn userPoolId={USERPOOL_ID} clientId={CLIENT_ID} awsRegion={AWS_REGION} loginCallback={setCognitoUser}></SignIn>
            }
            {loggedIn && 
                <ChatUi websocketBaseUrl={WEBSOCKET_BASE_URL} cognitoUserSession={cognitoUserSession}></ChatUi>
            }
        </div>
    )
};

export default Mnemosyne
const configuration = {
    'mnemosyne.digital-platform.io':{
        "websocket_base_url": "wss://mqnlz3ny4l.execute-api.us-east-1.amazonaws.com/LATEST?token=",
        "userpool_id": "us-east-1_HKQmGejUS",
        "identity_pool_id": 'us-east-1:af3c77aa-df01-4e61-88ab-cbb2dd099473',
        "aws_region": "us-east-1",
        "client_id": "7duclaspblpm9hc25l45v3l0sm"
    },
    'dev.mnemosyne.digital-platform.io':{
        "websocket_base_url": "wss://rshkvhkis0.execute-api.eu-central-1.amazonaws.com/LATEST?token=",
        "userpool_id": "eu-central-1_2EndLMhuy",
        "identity_pool_id": 'us-east-1:af3c77aa-df01-4e61-88ab-cbb2dd099473',
        "aws_region": "eu-central-1",
        "client_id": "2thioapinc9tdin1nq0l2h8eou"
    }
}

export default configuration
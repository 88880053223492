import React from 'react';
import { Button, Paper, Typography, Box, Grid, Link, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Colors } from './colors.js';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import AWS from 'aws-sdk';



const background = '/img/background.png';
const logo = '/img/logo.svg'
/*Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_HKQmGejUS",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "7duclaspblpm9hc25l45v3l0sm",

    scopes: [""]
  },
});*/


export default function SignIn({userPoolId, clientId, loginCallback, awsRegion}) {
  const userPoolUrl = "cognito-idp."+awsRegion+".amazonaws.com/"+userPoolId
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emptyUsername, setEmptyUsername] = React.useState("");
  const [emptyPassword, setEmptyPassword] = React.useState("");
  const login = function () {
    setEmptyUsername("");
    setEmptyPassword("");
    if (username === "") {
      setEmptyUsername("The username is empty, please try again. ")
    }
    if (password === "") {
      setEmptyPassword("The password is empty, please try again. ")
    }
    const authenticationData = {
      Username : username,
      Password : password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const poolData = { 
      UserPoolId : userPoolId,
      ClientId : clientId
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
        Username : username,
        Pool : userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            //const accessToken = result.getAccessToken().getJwtToken();
            /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
            const idToken = result.idToken.jwtToken;
            const logins = {}
            logins[userPoolUrl] = idToken
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: 'us-east-1:af3c77aa-df01-4e61-88ab-cbb2dd099473',
              Logins: logins
            });     
            loginCallback(cognitoUser)
        },
        onFailure: function(err) {
          setEmptyPassword("The username or password is incorrect, please try again. ");
        },
    });

  }
  return (
    <Paper style={{
      backgroundImage: `url(${background})`, backgroundSize: 'cover', position: 'fixed',
      backgroundRepeat: "no-repeat", width: "100%", height: "100vh", overflowY: "auto"
    }}>
      <Box display="grid" gridTemplateColumns="repeat(auto-fill, 5em)" gap={2} style={{ marginLeft: 20, marginTop: 15, height: 40 }}>
        <Box gridColumn="span 1" marginRight={2} position="relative">
          <img src={logo} height="40" alt="Deloitte" />
        </Box>
      </Box>
      <Box style={{ marginTop: "8%", marginLeft: "10%" }}>
        <Typography component="h1" variant="h4" style={{ color: "white" }}>
        </Typography>
        <Box mt={2}>
        </Box>
        <Typography variant="subtitle1" style={{ color: "white" }}>
        </Typography>
      </Box>
      <Box style={{ marginTop: "40px", marginLeft: "10%" }}>
        <Typography component="h1" variant="h4" style={{ color: "black" }}>
          Mnemosyne Login
        </Typography>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2} style={{ marginTop: "22px" }}>
            <Grid item xs={10} sm={8}>
              <TextField
                error={(emptyUsername !== "") || (emptyPassword.includes("incorrect"))}
                variant="filled"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onChange={(event) => { setUsername(event.target.value) }}
                inputProps={{ style: { background: "white", borderRadius: "5px" } }}
                helperText={emptyUsername}
              />
            </Grid>
            <Grid item xs={10} sm={8}>
              <TextField
                error={emptyPassword !== ""}
                variant="filled"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(event) => { setPassword(event.target.value) }}
                autoComplete="current-password"
                inputProps={{ style: { background: "white", borderRadius: "5px" } }}
                helperText={emptyPassword}
              />
            </Grid>
            <Grid item xs={10} sm={8}>
              <FormControlLabel
                control={<Checkbox value="rememberMe" color="primary" style={{ color: "grey" }} />}
                label="Remember me" style={{ color: "grey" }}
              />
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" style={{ marginTop: "5px" }}              >
                <Button type="submit" variant="contained" style={{ mt: 3, mb: 2, backgroundColor: Colors.blue, color: "white" }}
                  onClick={login}>
                  Login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={5} sm={4}>
              <Box display="flex" justifyContent="flex-start">
                <Link href="#" variant="body2" style={{ color: "grey" }} disabled>
                  Password Forgotten
                </Link>
              </Box>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Box display="flex" justifyContent="flex-end">
                <Link href="#" variant="body2" style={{ color: "grey" }} disabled>
                  Request Access
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper >
  );
}


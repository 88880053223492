import {
    Box,
    Grid,
    IconButton,
    TextField,
    Button,
    Tooltip,
    Typography,
    Avatar,
    Paper
  } from "@mui/material";
  
import SendIcon from "@mui/icons-material/Send";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {useState, useEffect, useRef, useMemo} from 'react'
import AWS from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid';
import React from 'react'
import { ThreeDots } from  'react-loader-spinner'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircularProgress, {
    circularProgressClasses
} from '@mui/material/CircularProgress';

const uploadStatusTextMapping = { 
    "documentUploaded": "Document uploaded",
    "textExtracted": "Text extracted",
    "embeddingsDocumentsCreated": "Embedding documents created",
    "embeddingDocumentEmbedded": "Embedding document embedded",
    "summaryGenerated": "Summary generated" 
};


const DocumentList = ({documents}) => {
    return (
        <Grid item container
        direction="row"
        justifyContent="flex-end"
        alignItems="center" xs={4}>
        {
          documents.map((doc) => (
            <Tooltip title={doc}>
              <DescriptionOutlinedIcon sx={{ 'fontSize': '2.0rem' }}></DescriptionOutlinedIcon>
            </Tooltip>
          ))
        }
      </Grid>
    )
}

const SessionId = ({sessionId}) => {
    return (
        <Grid item container
        direction="row"
        justifyContent="flex-end"
        alignItems="center" xs={3}>
        <span>Session Id: {sessionId}</span>
      </Grid>
    )
}
const Settings = () => {
    const openModelDialog = () => {}
    return (
        <Grid item container
        direction="row"
        justifyContent="flex-end"
        alignItems="center" xs={1}>
        <IconButton onClick={openModelDialog}><SettingsOutlinedIcon sx={{ 'fontSize': '2.0rem', color: 'black' }}></SettingsOutlinedIcon> </IconButton>
    </Grid>
    )
}

const Message = ({message}) => {
    const isBot = message.sender === "Mnemosyne";

    return (
        <Box
        sx={{
          display: "flex",
          justifyContent: isBot ? "flex-start" : "flex-end",
          mb: 2,
        }}
      >
        <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: "center",
        }}
      >
            <Avatar sx={{ bgcolor: isBot ? "primary.main" : "secondary.main" }}>
            {isBot ? "M" : "U"}
            </Avatar>
            <Paper
            variant="outlined"
            sx={{
                p: 2,
                ml: isBot ? 1 : 0,
                mr: isBot ? 0 : 1,
                backgroundColor: isBot ? "primary.light" : "secondary.light",
                borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
            }}
            >
            { !message.image && 
                <Typography sx={{whiteSpace: "pre-line"}}>{message.text}{message.loading && <ThreeDots 
            height="30" 
            width="30" 
            radius="5"
            color="#4fa94d" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
             />}</Typography>
            }
            { message.image && 
                <img src={message.image} />
            }
            </Paper>
        </Box>
    </Box>
    )
}
/**
 * This class handles websocket connectivity to mnemosyne. It needs to be created as a singleton by the application. 
 */
class MnemosyneWebsocket {
    constructor(url, sessionIdChanged,messagesChanged,documentListChanged,documentStatusChanged){
        this.sessionId = "-1"
        this.messages = []
        this.documentStatus = []
        this.sessionIdChanged = sessionIdChanged
        this.messagesChanged = messagesChanged
        this.documentListChanged = documentListChanged
        this.documentStatusChanged = documentStatusChanged
        this.websocket = new WebSocket(url)
        this.websocket.addEventListener("open", (event) => {
            console.log("connected")
        })
        this.websocket.addEventListener("message", (event) => {
            var messagesChanged = false
            var sessionIdChanged = false
            var documentStatusChanged = false
            const message = JSON.parse(event.data)
            const ensureDocumentStatusExists = (documentName) => {
                if(!this.documentStatus[documentName]){
                    this.documentStatus[documentName] = this.createDocumentStatusObject()
                }
            }
            if(message.type === "heartBeat"){
                return
            }
            if(message.connectionId) {
                this.sessionId = message.connectionId
                sessionIdChanged = true
            }
            if(message.type === "chatMessage" ) {
                this.messages = this.messages.concat(this.createMessageObject(uuidv4(),message.content,"Mnemosyne","message",false))
            }
            if(message.type === "chatMessageStream" && message.index === 0) {
                this.messages = this.messages.concat(this.createMessageObject(uuidv4(),message.content,"Mnemosyne","message",true))
            }
            if(message.type === "chatMessageStream" && message.index > 0) {
                this.messages[this.messages.length-1].text += message.content
            }
            if (message.type === "streamDone") {
                this.messages[this.messages.length-1].loading = false
            }
            if(message.type === "streamDone" || message.type === "chatMessageStream" || message.type === "chatMessage"){
                messagesChanged = true
            }
            if(message.type === 'generatedImagePart' && message.index === 0) {
                const newMessage = this.createMessageObject(uuidv4(),message.content,"Mnemosyne","message",true)
                newMessage.image = "data:image/png;base64, "+message.content
                this.messages = this.messages.concat(newMessage)
            }
            if(message.type === 'generatedImagePart' && message.index > 0) {
                if(this.messages[this.messages.length-1].image)
                    this.messages[this.messages.length-1].image += message.content
            }
            if(message.type === 'generatedImageEnd') {
                this.messages[this.messages.length-1].loading = false
                messagesChanged = true
            }
            if(message.type === "documentUploaded") {
                const documentName = message.content
                ensureDocumentStatusExists(documentName)
                this.documentStatus[documentName].documentUploaded = true
                documentStatusChanged=true
            }
            if(message.type === "textExtracted") {
                const documentName = message.content
                ensureDocumentStatusExists(documentName)
                this.documentStatus[documentName].textExtracted = true
                documentStatusChanged=true
            }
            if(message.type === "embeddingsDocumentsCreated") {
                const documentName = message.content
                ensureDocumentStatusExists(documentName)
                this.documentStatus[documentName].embeddingsDocumentsCreated = true
                this.documentStatus[documentName].embeddingsDocumentsCreatedCount = message.count
                documentStatusChanged=true
            }
            if(message.type === "embeddingDocumentEmbedded") {
                const documentName = message.content
                ensureDocumentStatusExists(documentName)
                this.documentStatus[documentName].embeddingDocumentEmbedded = true
                if(!this.documentStatus[documentName].embeddingDocumentEmbeddedCount){
                    this.documentStatus[documentName].embeddingDocumentEmbeddedCount = 0
                }
                this.documentStatus[documentName].embeddingDocumentEmbeddedCount = this.documentStatus[documentName].embeddingDocumentEmbeddedCount+1
                documentStatusChanged=true
            }
            if(message.type === "summaryGenerated") {
                const documentName = message.content
                ensureDocumentStatusExists(documentName)
                this.documentStatus[documentName].summaryGenerated = true
                documentStatusChanged=true
            }
            if(sessionIdChanged){
                this.sessionIdChanged(this.sessionId)
            }
            if(message.type==="documentList"){
                this.documentListChanged(message.content)
            }
            if(messagesChanged){
                this.messagesChanged(this.messages)
            }
            if(documentStatusChanged) {
                this.documentStatusChanged(this.documentStatus)
            }
            if(this.callbacks && this.callbacks[message.type]){
                this.callbacks[message.type].forEach(element => {
                    element(message)
                });
            }
        })
    
        this.heartBeat = () => {
            if(this.websocket.readyState === 1){
                const message = { "type": "heartBeat", "content": "ping" };
                this.websocket.send(JSON.stringify(message));
            }
        }
        //Implementing the setInterval method
        this.hearBeatInterval = setInterval(this.heartBeat, 5000);
        this.messages = []
    }
    createMessageObject(messageId,content,sender,type,loading) {
        return {
            id: messageId,
            text: content,
            sender: sender,
            type: type,
            loading: loading
          }
    }
    createDocumentStatusObject(){
        return {
            documentUploaded:undefined,
            textExtracted:undefined,
            embeddingsDocumentsCreated:undefined,
            embeddingDocumentEmbedded:undefined,
            summaryGenerated:undefined
        }
    }
    getConnectPromise() {
        return new Promise((resolve,reject) => {
            this.websocket.onopen = () => {
                resolve(this)
            }
            if(this.websocket.readyState === 1){
                resolve(this)
            }
            this.websocket.onerror = error => reject(error)
        })
    }
    registerCallBack(messageType, callback) {
        if(!this.callbacks){
            this.callbacks = {}
        }
        if(!this.callbacks[messageType]){
            this.callbacks[messageType] = []
        }
        this.callbacks[messageType] = this.callbacks[messageType].concat(callback)
    }
    send(message){
        if(this.websocket.readyState === 0){
            this.getConnectPromise().then(socket => socket.send(message))
            return
        }
        if(message.type === "chatMessage" && !message.hidden ) {
            this.messages = this.messages.concat(this.createMessageObject(uuidv4(),message.content,"User","message",false))
            this.messagesChanged(this.messages)
        }
        this.websocket.send(JSON.stringify(message))
    }
    close(){
        console.log("Closing")
        clearInterval(this.hearBeatInterval);
        this.websocket.close()
    }
}
/**
 * The UploadDialog handles uploading files from the sidebar to mnemosyne.
 */
const UploadDialog = ({cognitoUserSession, websocket}) => {
    const [file, setFile] = useState(undefined)
    const [s3uploadurl,setS3uploadurl] = useState(undefined)
    const [callbackRegistered, setCallbackRegistered] = useState(false)
    const uploadDisabled = !(s3uploadurl && file)
    const handleInputChange = (event) => {
        setFile(event.target.files[0])
    }
    if(file && !s3uploadurl) {
        var message = {}
        message.type = "generateUploadUrl";
        message.content = file.name
        if(!callbackRegistered){
            websocket.registerCallBack("generateUploadUrl",(message)=>{
                setS3uploadurl(message.content)
            })
        }
        websocket.send(message);
    }
    const uploadFile = () => {
        var data = new FormData()
        for(var k in s3uploadurl["fields"]) {
            data.append(k,s3uploadurl["fields"][k])
        }
        Object.assign(data, s3uploadurl['fields'])
        data.append('file', file)
        const requestOptions = {
          method: 'POST',
          body: data
        };
        fetch(s3uploadurl.url, requestOptions)
          .then(response => console.log(response));
        setS3uploadurl(undefined)
        setFile(undefined)
    }
    return (
        <div>
            <input type="file" name="file" onChange={handleInputChange} /> <Button disabled={uploadDisabled} onClick={uploadFile}>Upload</Button>
        </div>
    )
}

/**
 * The Component dispays the vertical list of documents, currently present in the sidebar.
 */
const LargeDocumentList = ({documentStatus}) => {
    const documents = Object.keys(documentStatus)
    return (
        <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
            {documents?.map((document) => (
                <Grid container columns={24}>
                    <Grid item xs={19}><Typography noWrap>{document}</Typography></Grid>
                    <Grid item xs={1}>
                        {documentStatus[document]?.documentUploaded && 
                            <Tooltip title={uploadStatusTextMapping["documentUploaded"]} >
                                <CheckCircleOutlineOutlinedIcon sx={{ 'font-size': '1rem', color: 'green' }}></CheckCircleOutlineOutlinedIcon>
                            </Tooltip>
                        }
                    </Grid>
                    <Grid item xs={1}>
                    {documentStatus[document]?.textExtracted && 
                        <Tooltip title={uploadStatusTextMapping["textExtracted"]}>
                            <CheckCircleOutlineOutlinedIcon sx={{ 'font-size': '1rem', color: 'green' }}></CheckCircleOutlineOutlinedIcon>
                        </Tooltip>
                    }
                    </Grid>
                    <Grid item xs={1}>
                    {documentStatus[document]?.embeddingsDocumentsCreated && 
                        <Tooltip title={uploadStatusTextMapping["embeddingsDocumentsCreated"] + "("+documentStatus[document]?.embeddingsDocumentsCreatedCount+")"}>
                            <CheckCircleOutlineOutlinedIcon sx={{ 'font-size': '1rem', color: 'green' }}></CheckCircleOutlineOutlinedIcon>
                        </Tooltip>
                    }
                    </Grid>
                    <Grid item xs={1}>
                    {documentStatus[document]?.embeddingDocumentEmbedded && 
                        <Tooltip title={uploadStatusTextMapping["embeddingDocumentEmbedded"] + "("+documentStatus[document]?.embeddingDocumentEmbeddedCount+")"}>
                            { documentStatus[document]?.embeddingDocumentEmbeddedCount<documentStatus[document]?.embeddingsDocumentsCreatedCount && 
                                <CircularProgress
                                    variant="determinate"
                                    disableShrink
                                    sx={{
                                        // color: (theme) =>
                                        //     theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                                        color: 'green',
                                        animationDuration: '550ms',
                                        display: "inline-block",
                                        left: 0,
                                        [`& .${circularProgressClasses.circle}`]: {
                                            strokeLinecap: 'round',
                                        },
                                    }}
                                    value={100*documentStatus[document]?.embeddingDocumentEmbeddedCount/documentStatus[document]?.embeddingsDocumentsCreatedCount}
                                    size={15}
                                    thickness={4}
                                />
                            }
                            { documentStatus[document]?.embeddingDocumentEmbeddedCount===documentStatus[document]?.embeddingsDocumentsCreatedCount &&     
                                <CheckCircleOutlineOutlinedIcon sx={{ 'font-size': '1rem', color: 'green' }}></CheckCircleOutlineOutlinedIcon>
                            }
                        </Tooltip>
                    }
                    </Grid>
                    <Grid item xs={1}>
                        {documentStatus[document]?.summaryGenerated && 
                            <Tooltip title={uploadStatusTextMapping["summaryGenerated"]}>
                                <CheckCircleOutlineOutlinedIcon sx={{ 'font-size': '1rem', color: 'green' }}></CheckCircleOutlineOutlinedIcon>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
            ))}
        </Box>
    )
}


/**
 * The ChatUI Component contains the overall User Interfaces presented to the user. It manages the application state and creates the connection singleton-
 */
class ChatUi extends React.Component {

    componentDidMount(){
        const url = this.state.websocketBaseUrl + this.state.cognitoUserSession.getIdToken().jwtToken
        this.connection = new MnemosyneWebsocket(
            url,
             (sessionId) => this.setState({sessionId:sessionId}),
             (messages) => this.setState({messages:messages}),
             (documents) => this.setState({documents:documents}),
             (documentStatus) => this.setState({documentStatus:documentStatus})
             )

        if(!this.state.dialogStarted){
            this.setState({
                dialogStarted: true
            })
            //Prompt model to introduce itself
            const message = {};
            message.type = "chatMessage";
            message.content = "Introduce yourself and explain your capabilities.";
            message.hidden = true
            this.connection.getConnectPromise().then((conn) => conn.send(message)).catch(err => console.log(err));
        }
    }
    componentWillUnmount(){
        this.connection.close()
    }

    handleInputChange(event) {
        this.setState({input:event.target.value});
    };

    handleSend() {
        if (this.state.input.trim() !== "") {
            // console.log(input);
            var text = this.state.input
            var message = {};
            message.type = "chatMessage";
            message.content = text;
            this.connection.send(message)
            this.setState({input:""});
        }
      };
    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            documentStatus: {},
            sessionId: "-1",
            messages: [],
            websocketBaseUrl: props.websocketBaseUrl,
            cognitoUserSession: props.cognitoUserSession,
            dialogStarted: false,
            input: undefined
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSend = this.handleSend.bind(this)
    }
    render() {
        return (
            <Grid container spacing={1}>
                <Grid container item spacing={3}>
                    <Grid item xs={3}>
                        <span style={{ 'fontSize': '2.0rem' }}>Mnemosyne</span>
                    </Grid>
                    <Grid item xs={5}>
                        <DocumentList documents={this.state.documents}/>
                    </Grid>
                    <Grid item xs={3}>
                        <SessionId sessionId={this.state.sessionId}/>
                    </Grid>
                    <Grid item xs={1}>
                        <Settings/> 
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={3}>
                        <UploadDialog cognitoUserSession={this.state.cognitoUserSession} websocket={this.connection}></UploadDialog>
                        <LargeDocumentList documentStatus={this.state.documentStatus}></LargeDocumentList>
                    </Grid>
                    <Grid item xs={9}>
                        <Box
                        sx={{
                            height: "85vh",
                            display: "flex",
                            flexDirection: "column",
                            bgcolor: "grey.200",
                            width: "100%"
                        }}
                        >
                            <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
                                {this.state.messages?.map((message) => (
                                    <Message key={message.id} message={message}/>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <TextField
                                size="small"
                                fullWidth
                                placeholder="Type a message"
                                variant="outlined"
                                value={this.state.input}
                                onChange={this.handleInputChange}
                                onKeyDown={(ev) => {
                                    // console.log(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        this.handleSend()
                                        // Do code here
                                        ev.preventDefault();
                                    }
                                }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                endIcon={<SendIcon />}
                                onClick={this.handleSend}
                                >
                                Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
export default ChatUi
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect } from 'react'
//import Main from "./Main";
//import signIn from "./signIn";

import Mnemosyne from './Mnemosyne'

export default function App() {
  useEffect(() => {
    document.title = 'Mnemosyne'
}, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Mnemosyne />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}